$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.section {
  &.explorer {
    background-color: #fff;
    display: flex;
    height: 100%;
    opacity: 1;
    width: 100%;

    .background {
      background-image: url("../../../assets/images/bg-compo.jpg");
      background-position: left center;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      opacity: 1;
      position: absolute;
      transform: scaleX(-1);
      transition: opacity 1s;
      width: 100%;

      // &:before {
      //   background-color: rgba(#fff, 0.85);
      //   content: "";
      //   height: 100%;
      //   position: absolute;
      //   width: 100%;
      // }

      .mobile & {
        background-size: 100%;
        left: -15vw;
        transform: rotate(45deg);
        width: 160%;
      }
    }

    .explorer-intro {
      display: flex;
      flex-direction: column;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;

      .title {
        @include font-size(30, 36);

        font-weight: 400;
        left: 50%;
        position: absolute;
        text-align: center;
        top: 10%;
        transform: translate(-50%, 0);
        width: 80%;

        .mobile & {
          @include font-size(20, 24);

          align-items: center;
          display: flex;
          justify-content: center;
          left: auto;
          margin: 15% 20px 20px 20px;
          position: relative;
          text-align: center;
          top: auto;
          transform: none;
          width: auto;
        }
      }

      .options {
        align-items: center;
        display: flex;
        flex: 1 1 0;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        width: 90%;

        .mobile & {
          flex-direction: column;
          justify-content: flex-start;
          margin: 10% 40px;
          width: auto;
        }

        .option {
          align-items: center;
          background-color: rgba(#fff, 0.95);
          box-shadow: 0px 0px 30px rgba($shady-lady, 0.3);
          color: $base-font-color;
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 50%;
          justify-content: center;
          opacity: 1;
          text-align: center;
          text-decoration: none;
          transition: opacity .8s;
          width: 50%;

          .mobile & {
            flex: 1 1 0;
            height: auto;
            margin-bottom: 20px;
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 15px;
              margin-right: 0;
            }
          }

          &:active {
            background-color: rgba(#fff, 1);
          }

          &.down {
            opacity: 1;
            background-color: rgba(#fff, 1);
          }

          &:not(:last-child) {
            margin-right: 40px;
          }

          &.wrong-answer {
            opacity: 0;
          }

          &.right-answer {
            background-color: rgba(#fff, 1);
          }

          .icon {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 50px;
            margin-bottom: 20px;
            width: 100px;
          }

          .label {
            @include font-size(20, 24);
            @include letter-spacing(50);

            width: 60%;
            text-transform: uppercase;

            .mobile & {
              @include font-size(13, 17);
            }
          }
        }
      }
    }

    .main-wrapper {
      display: flex;
      flex-direction: column;
      flex: 1 1 80vw;
      overflow: hidden;

      .sub-header {
        background-color: #fff;
        display: flex;
        padding: 20px 40px;
        position: relative;

        .mobile & {
          padding: 12px 10px 10px 10px;
          justify-content: space-around;
        }

        .by-text {
          @include font-size(10, 14);

          margin-right: 20px;
        }

        .nav-button {
          @include font-size(12, 14);

          color: $manatee;
          text-decoration: none;

          @include first-letter-case;

          .mobile & {
            margin-right: 0;
          }

          &:hover {
            color: $primary-theme-color;
          }

          &.selected {
            color: $primary-theme-color;
            border-bottom: solid 2px $primary-theme-color;
          }

          &:not(:last-child) {
            margin-right: 40px;
          }

          > span {
            @include first-letter-case;
          }
        }
      }

      .sub-content {
        flex: 1 1 0;
        overflow: hidden;

        .sub-section {
          background-color: #fff;
          display: flex;
          height: 100%;
          width: 100%;
        }
      }
    }

    .mini-profile-wrapper {
      background-color: #fff;
      flex: 0 0 20vw;
      overflow: hidden;
      position: relative;

      .donut-column {
        padding: 20px;
      }
    }
  }
}
