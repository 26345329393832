$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.sub-section {
  display: flex;

  .mobile & {
    flex-direction: column;
  }

  .search-cols {
    display: flex;

    .back-col {
      flex: 0 0 0px;
      overflow: hidden;

      .back-button {
        @include font-size(12, 14);

        align-items: center;
        cursor: pointer;
        display: flex;

        &:before {
          background-image: url("../../../assets/icons/icon-arrow-left.svg");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 20px;
          content: "";
          display: block;
          height: 20px;
          width: 20px;
        }
      }
    }

    .mobile & {
      flex-direction: column;
      width: 100%;
      flex: 1 1 0;
      overflow: hidden;

      &.mobile-hidden {
        flex: 0 0 40px; //BASURA.. no va con auto...

        .back-col {
          display: flex;
          flex: 0 0 40px; //BASURA.. no va con auto...
          margin-left: 10px;
        }

        .main-col,
        .second-col {
          display: none;
        }
      }
    }

    .main-col {
      border-right: 1px solid $gallery;
      display: flex;
      flex: 0 0 20vw;
      overflow: hidden;
      transition: flex 0.5s ease-in-out, width 0.5s ease-in-out;
      width: 20vw;

      .mobile & {
        width: 100%;
        flex: 1 1 auto;
        flex-direction: column;
      }

      .search-container {
        margin-bottom: 20px;
        transition: width 0.5s ease-in-out;
        width: 100%;

        .mobile & {
          margin-bottom: 10px;
        }
      }

      .search-bar {
        border-color: $primary-theme-color;
        width: 100%;
        transition: border-radius 0.5s ease-in-out;

        .input-wrapper {
          transition: flex 0.5s ease-in-out;

          input {
            outline: none;
          }
        }
      }

      .list {
        transition: opacity 0.5s ease-in-out;
      }

      &.collapsed {
        flex: 0 0 80px;
        position: relative;
        width: 0px; //caca... ja hi ha el flex, y aquest fa merdes rares...

        &:after {
          background-image: url("../../../assets/icons/icon-arrow-left.svg");
          background-position: center center;
          background-repeat: no-repeat;
          background-size: 50px;
          content: "";
          cursor: pointer;
          display: block;
          height: 40px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 40px;
        }

        .search-container {
          width: 42px;

          .search-bar {
            border-radius: 50%;

            .input-wrapper {}
          }

          // CHAPUZA MAJOR... per weno... (Es perque no faci animacio raruna cuan toran a apareixer...)
          .mobile & {
            width: 100%;

            .search-bar {
              border-radius: 42px;
            }
          }
        }

        .list {
          opacity: 0;
          pointer-events: none;
        }
      }
    }

    .second-col {
      border-right: solid 1px #ddd;
      overflow: hidden;
      transition: width 0.5s ease-in-out;
      width: 0vw;

      .mobile & {
        background: #ddd;
        transition: flex 0.5s ease-in-out;
        flex: 0 0 0;
        width: 100%;
        bottom: 0px;
      }

      &.visible {
        width: 20vw;

        .mobile & {
          width: 100%;
          flex: 0 0 30vh;
        }
      }

      .cut-content {
        padding: 20px;

        .selection-list {
          flex: 1 1 0;
          overflow-y: auto;

          .concept-list-wrapper {
            display: flex;
            flex-wrap: wrap;
          }

          &:not(:only-child) {
            margin-bottom: 20px;
          }

          .brand-item-hover {
            color: $primary-theme-color;
          }

          .brand-item {
            @include font-size(14, 16);
            @include letter-spacing(20);

            align-items: center;
            display: flex;
            font-weight: 400;

            &:not(:last-child) {
              margin-bottom: 20px;
            }

            .name {
              flex: 1 1 0;

              @include ellipsis(100%);
            }

            .close-item {
              @include font-size(0);

              background-color: transparent;
              background-image: url("../../../assets/icons/close.png");
              background-position: center center;
              background-repeat: no-repeat;
              background-size: 10px 10px;
              color: $primary-theme-color;
              cursor: pointer;
              height: 20px;
              margin-left: 20px;
              margin-top: -0.2em;
              width: 20px;
            }
          }
        }

        .go-button-wrapper {
          display: flex;
          justify-content: center;
          min-width: 15vw;

          .nocibe-button {
            width: 100%;
          }
        }
      }
    }

    .cut-content { //para las 2 cols
      display: flex;
      flex-direction: column;
      height: 100%;

      // width: 20vw; //Tendria que ser el 20vw para que hiciera CUT, pero no va...
      width: 100%;

      .mobile & {
        width: 100%;
      }
    }
  }

  .search-perfume-results {
    flex: 1 1 auto;
    overflow: hidden;
    width: 100%;
    position: relative;

    .mobile & {
      overflow: visible;
      height: calc(100% - 40px); //BUUU.... baya lio aquestes "columnes"
   
    }

    .perfumes-list {
      .list-wrapper {
        padding: 0 20px;
      }
    }

    .perfume-row {
      .button {
        @include font-size(10, 12);

        padding: 5px 10px;
        width: 110px;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }
  }

  //Chapuzilla meterlo aqui abajo, no mha sortit amb tants ampersants per davant y per darrere...
  &.by-ingredients {
    .second-col {
      &.visible {
        width: 20vw;

        .mobile & {
          width: 100%;
          flex: 0 0 40vh;
        }
      }
    }
  }
}
