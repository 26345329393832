$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.section {
  &.profile {
    display: flex;
    flex-direction: column;
    padding: 40px;

    .mobile & {
      padding: 20px;
      display: block;
    }

    // .content,
    // .header {}

    .content {
      flex: 1 1 0;
      display: flex;
      flex-direction: column;
      overflow: hidden;

      .mobile & {
        overflow-y: auto;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .top {
        align-items: center;
        display: flex;
        justify-content: flex-start;
        margin-bottom: 40px;
        overflow: hidden;

        > div {
          width: 27vw;

          .mobile & {
            width: 100%;
          }
        }

        .desc-ing {
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          .ingredients-title {
            font-weight: 600;
            @include font-size(16);
          }
        }

        .selected_fragrances {
          height: 100%;
          p {
            margin-bottom: 40px;
          }
        }

        .mobile & {
          display: block;

          // flex: auto;
          margin-bottom: 0;
          overflow: visible;
        }
      }

      .column {

        .mobile & {
          // flex: auto;
          height: auto !important;
          margin-bottom: 20px;
        }
      }

      .main-donut-column {
        flex: 0 0 40vw;
        overflow: hidden;

        .mobile & {
          margin: 0; //Le quito el margin para que quede "mejor" con el sticky-button
          position: none;
        }

        .title {
          @include first-letter-case;
          @include font-size(24, 34);

          margin: 0 50px;

          .mobile & {
            @include font-size(20, 24);

            font-weight: 400;
            margin: 0 0 20px 0;
            text-align: center;
          }
        }

        .donut {
          display: flex;
          justify-content: center;

          .mobile & {
            display: block;
          }
        }
      }

      .sticky-button {
        // Un poco chapuzero "todos estos 20s y 40s". Es para que mida MAS del 100% y poder pintar el background de gris
        // background: #F7F7F7;
        // position: sticky;
        // top: -20px;
        // width: calc(100% + 40px);
        background: #fff;
        bottom: 0;
        margin: 0px -20px;
        padding: 20px;
        position: fixed;
        width: 100%;
        z-index: 1;

        // .button {
        //   font-size: 10px; //?? queda mejor, pero quizas es demasiado peqeno...
        // }

        .link-button {
          text-decoration: none;
        }
      }

      .ingredients-column {
        // overflow: hidden; //se cortaban los nombres de los ing de abajo, ahora parece que todo OK
        display: flex;
        flex-direction: column;
        overflow: auto;
        gap: 10px;

        .sub-title {
          @include first-letter-case;
        }
        .ingredients-title{
          .mobile & {
            margin-top: 10px;
            @include font-size(12);
            font-weight: 600;
          }
        }

        .ingredients-list {
          display: flex;
          flex-wrap: wrap;
          // flex: 1 1 0;
          // margin-bottom: 10px;
          // overflow: hidden;
          overflow-y: auto;
          // margin: auto;

          .ing-item {
            $margin: 10px;
            $width: 33%;

            display: flex;
            flex-direction: column;
            // height: calc(#{$width} - #{$margin} * 2);
            width: calc(#{$width} - #{$margin} * 2);
          }
        }

        .ingredients-less-dominant-wrapper {
          display: flex;
          flex-direction: column;
          max-height: 100px;
          overflow: hidden;
        }

        .ingredients-less-dominant-title {
          @include font-size(12, 18);

          font-weight: bold;
          margin-bottom: 10px;
          margin-left: 10px;
        }

        .ingredients-less-dominant-list {
          margin: 0 10px;
          overflow-y: auto;

          .ing-text {
            @include font-size(12, 20);

            margin-left: 5px;
          }
        }
      }

      .description-column {
        display: flex;
        flex-direction: column;
        // overflow: hidden; //CUIDAO!, que esto en mobile vete a saber!
        .sub-title{
          margin-left: -3vw;
          color: transparent
        }
        .mobile & {
          .sub-title{
            margin-left: 0;
            color: inherit
          }
        }
      }
      .ingredients-column {
        .mobile &{
          .sub-title{
            color: transparent
          }
        }
      }

      //ME LO LLEVO A PorfileDesc.scss
      //   .description {
      //     height: 100%;
      //     overflow-y: auto;
      //     text-align: left;

      //     .main {
      //       @include font-size(21, 28);

      //       font-weight: 400;
      //       margin-bottom: 20px;
      //       margin: 10px;
      //       overflow-y: auto;

      //       .mobile & {
      //         @include font-size(24, 34);

      //         font-weight: 400;
      //         margin: 0;
      //         text-align: center;
      //       }
      //     }

      //     .more {
      //       @include font-size(14, 24);

      //       font-weight: 400;
      //       margin: 10px;

      //       .mobile & {
      //         @include font-size(16, 26);

      //         font-weight: 400;
      //         margin: 0;
      //         text-align: center;
      //       }
      //     }
      //   }
      // }

      .sub-title {
        @include font-size(14, 20);

        border-bottom: 1px solid $shady-lady;
        font-weight: 400;
        margin-bottom: 20px;
        padding-bottom: 5px;
        text-align: left;

        .mobile & {
          font-weight: 400;
        }
      }

      .bottom {
        display: flex;
        justify-content: center;

        .mobile & {
          display: block;

          // margin-bottom: 60px;

        }
      }

      .left {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-start;

        .mobile & {
          &:empty {
            display: none;
          }
        }

        .button {
          margin: 0 20px;

          .mobile & {
            margin: 10px 2px 0 2px;
            width: 100%;
          }
        }
      }

      .right {
        display: flex;
        flex: 0 0 auto;
        justify-content: flex-end;

        .mobile & {
          width: 100%;
        }

        .button {
          margin: 0 20px;

          .mobile & {
            margin: 0;
            flex: 1 1 50%;
            width: 50%;

            &:not(:last-child) {
              margin-right: 10px;
            }
          }
        }

        .link-button {
          display: block;
          text-decoration: none;

          .mobile & {
            width: 100%;
          }
        }

        .nocibe-button {}
      }

      .link-button {
        text-decoration: none;
      }
    }
  }
}
