$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.perfume-view-wrapper {
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.perfume-full-view {
  background-color: #fff;
  display: flex;
  margin: 0 auto;
  padding: 20px;
  height: 80vh;
  left: 50%;
  width: 60vw;

  .left {
    background-color: #fff;
    display: flex;
    flex: 1 1 50%;
    margin: 40px 20px 20px 50px;

    .perfume-card {
      height: 100%;

      .card-content {
        overflow: visible;
        padding: 0;

        .info-top {
          background-color: rgba(#fff, 0.75);
          border-radius: 4px;
        }

        .image {
          margin-top: 80px;
          margin-bottom: 40px;
        }
      }

      .price {
        .from {
          font-weight: 400;
        }

        .euros {
          @include font-size(22);
        }
      }
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    flex: 1 1 50%;
    justify-content: center;
    margin: 40px 50px 20px 20px;

    .mobile &{
      display: block;
      margin: 20px;
    }

    .menu-list {
      display: flex;
      justify-content: flex-start;
      gap: 20px;
      text-align: left;

      .mobile & {
        margin-right: 20px;
      }

      .menu-item {
        @include font-size(10, 12);

        cursor: pointer;
        display: flex;
        margin-bottom: 20px;

        // margin-right: 15px;
        text-transform: capitalize;

        &.selected {
          font-weight: 400;
          position: relative;

          &:before {
            background-color: $primary-theme-color;
            content: "";
            display: block;
            height: 3px;
            left: 0;
            position: absolute;
            bottom: -6px;
            width: 100%;
          }
        }
      }
    }

    .current-section-container {
      flex: 1 1 0;
      overflow: hidden;
      text-align: left;
      position: relative;

      .current-section {
        overflow-y: auto;

        &.ingredients {
          display: flex;
          flex-direction: column;
          height: 100%;
          overflow-y: hidden;

          .mobile & {
            display: block;
          }

          .quad-wrapper {
            flex: 0 0 calc(70% - 20px);
            margin-bottom: 20px;
            .mobile & {
              height: 30vh;
            }
          }

          .ings-list-list {
            display: flex;
            flex-direction: column;
            flex: 1 1 0;
            overflow: hidden;
          }

          .ings-list-label {
            @include font-size(12, 20);

            // @include letter-spacing(25);

            font-weight: bold;
            margin-bottom: 10px;

            .mobile & {
              @include font-size(12, 14);
            }
          }

          .ings-list {
            flex: 1 1 0;
            overflow-y: auto;

            .ingredient {
              @include font-size(10, 12);
              @include letter-spacing(25);

              margin-bottom: 10px;
              margin-left: 10px;

              .mobile & {
                @include font-size(10, 12);

                margin-bottom: 5px;
                margin-left: 5px;
              }
            }
          }
        }

        &.description {
          @include font-size(14, 22);
          @include letter-spacing(25);

          font-weight: 400;
          height: 100%;
          overflow-y: auto;
          padding-right: 10px;
          margin-top: 5%;

          .mobile & {
            @include font-size(12, 16);

            margin: 0;
            padding: 0;
          }
        }

        &.families {
          @include font-size(13, 22);

          top: 50%;
          position: absolute;
          width: 100%;
          left: 50%;
          transform: translate(-50%, -50%);

          .mobile & {
            height: 100%;
            overflow: hidden;
            width: 100%;
          }

          .family-donut-column {
            .donut {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }

          .family-wrapper {
            width: 100%;
            text-align: center;

            .family-text, .subfamily-text {
              // @include first-letter-case;
              text-transform: capitalize;
            }

            .family-text {
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  .close-button {
    background-image: url("../../assets/icons/icon-close.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer;
    height: 20px;
    right: 20px;
    top: 20px;
    width: 20px;

    .mobile & {
      position: absolute;
    }
  }

  .mobile & {
    width: 100%;
    height: 100%;
    position: static;
    top: 0;
    left: 0;
    transform: none;
    overflow: scroll;
    display: block;

    &.popup {
      align-items: center;
      display: flex;
      flex-direction: column;

      .close-button {
        cursor: pointer;
      }

      .right {
        // margin: 20px 40px 20px 20px; //Basura el "40" que decentraba...
        margin: 20px 20px 20px 20px;

        .menu-list {
          .menu-item {
            @include font-size(9, 11);
          }
        }
      }
    }

    .flip-box-inner {
      flex: 1 1 0;
      height: 100%;
      position: relative;
      transform-style: preserve-3d;
      transition: transform 0.5s ease-in-out;
      width: 100%;
      z-index: 1;

      &.show-back {
        transform: rotateY(180deg);

        .front {
          pointer-events: none;

          .close-button {
            display: none;
          }
        }

        .back {
          pointer-events: all;

          .close-button {
            display: block;
          }
        }
      }

      .front,
      .back {
        backface-visibility: hidden;
        background: #fff;
        display: flex;
        height: 100%;
        position: absolute;
        transform: translate3d(0, 0, 0);
        width: 100%;
      }

      .back {
        transform: rotateY(180deg);
        pointer-events: none;

        // .close-button {
        //   display: none;
        // }
     
      }
    }

    .super-section-toggle {
      $height: 15px;

      cursor: pointer;
      align-items: center;
      display: flex;
      height: $height * 4;
      justify-content: space-between;
      padding: $height $height 0 $height;
      padding: $height;
      transition: transform 0.5s ease-in-out;
      width: round($height * 4 + $height / 2);

      &:before,
      &:after {
        border-radius: 50%;
        border: 1px solid #fff;
        content: "";
        display: block;
        height: $height;
        width: $height;
      }

      &.one {
        &:before {
          background-color: transparent;
        }

        &:after {
          background-color: #fff;
        }
      }

      &.two {
        &:before {
          background-color: #fff;
        }

        &:after {
          background-color: transparent;
        }
      }
    }
  }
}
