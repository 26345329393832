$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}


.donut {
  position: relative;
  // height: 100%;
  // width: 100%;

  // > svg {
  //   height: 100%;
  //   width: 100%;
  // }

  // .root {
  //   // transition: transform 1s;
  // }

  .clip-path {
    transition: transform 1s;
  }
  .outer-circle,.inner-circle{
    stroke: #ccc;
  }
  .outer-circle{
    fill: none; //#F7F7F7;
  }
  .inner-circle{
    fill: none; //#F7F7F7;
  }
  .ring-wrapper{
    pointer-events: none;
    .family-ring-label{
      font-size: 14px;
    }
    .mobile &{
      .family-ring-label{
        font-size: 10px;
      }
    }
  }

  .sub-family-text {
    fill: #fff;
    font-size: 10px;
    .mobile &{
      font-size: 8px;
    }
  }

  .label-circle {
    fill: none;
    stroke: #888;
  }

  .donut-label {
    // @include font-size(12);
    font-size: 12px;
    fill: #888;
    stroke: none;
  }

  .avatar {
    fill: #f00;
  }

  .item {
    // &.selected {
    //   path {
    //     stroke: #444;
    //     stroke-width: 4px;
    //   }
    // }
    .image-container{
      pointer-events: none;
    }
    .sub-family-wedge{
      pointer-events: none;
    }
    image {
      transition: transform 1s;
      transform: rotate(180deg);
      cursor: pointer;
    }
    &.no-image{
      image{
        display: none;
      }
    }
  }

  .wheel-center {
    position: absolute;
    top: 50%;
    left: 50%;
    // background-image: url("../../assets/icons/nocibe.png");
    border: solid 1px #888;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 80%;
    background-color: white;
    transform: translate(-50%, -50%);
    border-radius: 140px;
  }

  .selection-mark {
    position: absolute;
    width: 20px;
    height: 20px;

    // .line {
    //   width: 100%;
    //   height: 1px;
    //   background: #444;
    //   opacity: 0.3;
    // }

    .arrow {
      width: 0;
      height: 0;
      border-style: solid;

    }
    &.mark-right{
      top: 50%;
      right: 0px;
      transform: translate(0, -50%);
      .arrow{
        border-width: 8px 12px 8px 0;
        border-color: transparent #533 transparent transparent;
      }
    }
    &.mark-bottom{
      bottom: 0px;
      left: 50%;
      transform: translate(-50%, 0);
      .arrow {
        border-width: 0 8px 10px 8px;
        border-color: transparent transparent #533 transparent;
      }
    }
  }

}
