$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
@import "assets/fonts/Avenir/stylesheet";

@page {
  size: A4;
  margin: 0;
}

@media print {
  .noprint {
    display: none !important;
  }

  body {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    direction: ltr !important;
    margin: 0 !important;
    height: 297mm !important;
    width: 210mm !important;
    overflow: hidden !important;
  }

  .app-header {
    display: none !important;
  }

  .app-content {
    overflow: unset !important;
  }

  #print-object {
    display: flex !important;
    flex-wrap: wrap !important;
    width: 100% !important;
  }

  .discount {
    .description {
      p {
        font-size: 12px !important;
        line-height: 14px !important;
      }
    }
  }

  .info-bottom {
    .name-and-brand {
      margin-bottom: 5px !important;

      .brand,
      .name {
        .mobile & {
          font-size: 8px !important;
          line-height: 10px !important;
        }
      }
    }

    .actions {
      display: none !important;
    }
  }

  .recomendations {
    .after-list {
      display: none !important;
    }

    .card-list {
      margin: 0 !important;
      min-height: auto !important;

      justify-content: center !important;
      width: 210mm !important;
   
    }

    // .rest {
    //   display: block !important;
    //   flex: none !important;
    //   position: absolute;
    //   top: 295px;
    //   left: 0;
    // }

    .separator {
      display: block !important;
    }

    .cards-wrapper {
      padding: 0 !important;
      overflow: hidden !important;

      justify-content: center !important;
      width: 210mm !important;
   
    }

    .perfume-card {
      // flex: 0 0 percentage(1/4) !important;
      $percentage: percentage(1 / 4);
      $margin: 5px;

      $calc: calc(#{$percentage} - #{$margin} * 2);
      // $calc: 40mm;

      flex-basis: $calc !important;
      margin-left: $margin !important;
      margin-right: $margin !important;
      margin-bottom: 0 !important;
      margin-top: 0 !important;
      height: 225px !important;
      width: auto !important;

      &:hover {
        box-shadow: none !important;
      }

      .image {
        margin: 10px;
      }

      .mobile & {
        $percentage: percentage(1 / 4);
        $margin: 5px;
        $calc: calc(#{$percentage} - #{$margin} * 2);

        flex-basis: $calc !important;
        margin: $margin !important;
        height: 235px !important;
      }
    }
  }
}

html {
  box-sizing: border-box;
  font-size: $base-font-size * 1px;
  height: 100%;
  line-height: $base-line-height;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;

  // background-color: $pearl;
  color: $base-font-color;
  font-family: $base-font-family;
  font-weight: $base-font-weight;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  user-select: none;
}

#root {
  height: 100%;
}

.app {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  &.mobile {
    .app-content {
      .section {
        padding: 0;

        .content {
          padding: 20px;
        }
      }
    }
  }

  .app-content {
    background-color: #F7F7F7;
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    overflow: hidden;
    position: relative;

    .section {
      overflow-x: hidden;
      overflow-y: auto;

      // display: flex;
      flex: 1 1 0;
    }
  }
}

.preloader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  .text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    white-space: nowrap;
  }
}
