$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.wish-list-panel {
  background-color: #fff;
  display: flex;
  flex-direction: column;
  flex: 0 0 20vw;
  max-width: 20vw;
  padding: 20px;
  z-index: 1;

  .mobile & {
    flex: 0 0 85vw;
    max-width: 85vw;
  }

  .title {
    @include font-size(20, 20);
    color: $primary-theme-color;
    font-weight: 600;
    margin-bottom: 20px;
  }

  .close-button {
    background-image: url("../../assets/icons/icon-close.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    color: gray;
    cursor: pointer;
    height: 15px;
    position: absolute;
    right: 20px;
    top: 20px;
    width: 15px;
  }

  .wish-list {
    flex: 1 1 0;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;

    .wish-list-empty-text {
      @include font-size(12, 14);

      font-weight: 400;
      left: 50%;
      position: absolute;
      text-align: center;
      text-transform: uppercase;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }

    .perfume-row {
      padding: 10px;
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      .row-wrapper {
        .row-top {
          padding-bottom: 10px;

          .mobile & {
            flex-wrap: nowrap;

            .remove-btn {
              margin-top: 0px;
            }
          }
        }
      }

      .main-info-wrapper {
        .information {
          margin-left: 10px;
        }
      }

      .price-and-buy {
        .mobile & {
          flex-direction: column;
        }

        .price {
          display: flex;
          flex-direction: column;
          flex: 0 0 auto;

          .mobile & {
            margin-bottom: 5px;
            margin-right: 0;
            width: 100%;
          }

          .from {
            margin-bottom: 5px;
          }
        }

        .button {
          flex: 1 1 0;

          .mobile & {
            width: 100%;
          }
        }
      }
    }
  }
}

.overlayed-view-enter, .overlayed-view-appear {
  .wish-list-panel {
    transform: translate(100%, 0);
  }
}

.overlayed-view-enter-active, .overlayed-view-appear-active {
  .wish-list-panel {
    transition: transform .5s;
    transform: translate(0, 0);
  }
}

.overlayed-view-exit {
  .wish-list-panel {
    transform: translate(0, 0);
  }
}

.overlayed-view-exit-active {
  .wish-list-panel {
    transition: transform .5s;
    transform: translate(100%, 0);
  }
}
