$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.question {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    &.question-transition-enter {
      left: 100%;
    }

    &.question-transition-enter-active {
      left: 0;
      transition: left 1.2s;
    }

    &.question-transition-exit {
      left: 0;
    }

    &.question-transition-exit-active {
      left: -100%;
      transition: left 1.2s;
    }

    &.buttons-question {
      display: flex;
      flex-direction: column;

      .title {
        @include font-size(30, 36);

        font-weight: 400;
        left: 50%;
        position: absolute;
        top: 10%;
        transform: translate(-50%, 0);

        .mobile & {
          @include font-size(20, 24);

          align-items: center;
          display: flex;
          justify-content: center;
          left: auto;
          margin: 20px;
          position: relative;
          text-align: center;
          top: auto;
          transform: none;
        }
      }

      .options {
        align-items: center;
        display: flex;
        flex: 1 1 0;
        justify-content: center;
        margin: 0 auto;
        position: relative;
        width: 90%;

        .mobile & {
          flex-direction: column;
          justify-content: flex-start;
        }

        .option {
          align-items: center;
          background-color: rgba(#fff, 0.9);
          box-shadow: 0px 0px 30px rgba($shady-lady, 0.3);
          cursor: pointer;
          display: flex;
          flex-direction: column;
          height: 50%;
          justify-content: center;
          text-align: center;
          width: 50%;
          opacity: 1;
          transition: opacity .8s;

          .mobile & {
            flex: 1 1 0;
            height: auto;
            margin-bottom: 20px;
            width: 100%;

            &:not(:last-child) {
              margin-bottom: 20px;
              margin-right: 0;
            }
          }

          &:active {
            background-color: rgba(#fff, 1);
          }

          &.down {
            opacity: 1;
            background-color: rgba(#fff, 1);
          }

          &:not(:last-child) {
            margin-right: 40px;
          }

          &.wrong-answer {
            opacity: 0;
          }

          &.right-answer {
            background-color: rgba(#fff, 1);
          }

          .icon {
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            height: 50px;
            margin-bottom: 20px;
            width: 100px;
          }

          .label {
            @include font-size(20, 30);
            @include letter-spacing(50);

            width: 60%;
            text-transform: uppercase;

            .mobile & {
              @include font-size(13, 17);
            }
          }
        }
      }
    }

    &.perfumes-question {
      background-color: #fff;
      display: flex;

      .perfumes-left {
        display: flex;
        flex-direction: column;
        flex: 1 1 0;
        overflow: hidden;
        padding: 40px 100px;
        position: relative;

        .mobile & {
          padding: 20px;
          line-height: 1;
        }

        .title {
          @include first-letter-case;

          flex: 0 0 30px;
          font-weight: 400;
          height: 30px;
          line-height: 30px;
          margin-bottom: 20px;
          text-align: left;
          transition: font-size .5s ease-in-out;

          .mobile & {
            // flex: auto;
            font-size: 14px !important;

            // height: auto;
            line-height: 1.2;
            margin-bottom: 2%;
          }

          &.small {
            font-size: 16px;
          }

          &.large {
            font-size: 22px;
          }
        }

        .search-bar {
          $margin: 15px;

          .mobile & {
            $margin: 10px;
          }

          border-color: $primary-theme-color;
          border-radius: $margin * 2;
          margin-bottom: 20px;
          width: 100%;

          .input-wrapper {
            input[type="text"] {
              @include font-size(18, 22);
              @include letter-spacing(20);

              margin: $margin ($margin * 2);
              outline: none;
              padding: 0;

              .mobile & {
                @include font-size(10, 12);
                @include letter-spacing(10);
              }
            }
          }

          .icon {
            margin-right: $margin * 2;
          }
        }

        .results-wrapper {
          position: relative;
          flex: 1 1 0;
          overflow: hidden;
          display: flex;
          min-height: 30vh;

          .loading,
          .no-results {
            background: transparent;
          }

          .reminder-message {
            .text {
              @include font-size(18, 22);

              font-weight: 400;

              &:before {
                background-image: url("../../../assets/icons/icon-arrow-up.svg");
                background-position: center center;
                background-repeat: no-repeat;
                background-size: contain;
                bottom: 50%;
                content: "";
                height: 50px;
                left: 50%;
                margin-bottom: 20px;
                position: absolute;
                transform: translate(-50%, -50%);
                width: 50px;
              }
            }
          }
        }
      }

      .perfumes-right-wrapper {
        display: flex;

        // CREO QUE ARREGLA lo del "etape 2/4 wrong flex" (1)
        width: 30vw;
        max-width: 500px;

        .mobile & {
          width: 100%;
          max-width: 100%;
        }
      }

      .perfumes-right {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        opacity: 1;
        padding: 40px;
        transition: opacity .5s;
        
        // CREO QUE ARREGLA lo del "etape 2/4 wrong flex" (1)
        width: 100%;

        .mobile & {
          height: 100%;
          max-width: 100%;
          padding: 20px;
          position: absolute;
          right: 0;
          transition: right .6s;
          width: 85vw;

          &.hidden {
            right: -100%;
          }
        }

        &.hidden {
          opacity: 0;
        }

        .title {
          @include font-size(12);

          font-weight: 400;
          margin-bottom: 20px;
        }

        .selection {
          flex: 1 1 0;
          margin-bottom: 20px;
          overflow-y: auto;

          .mobile & {
            margin-bottom: 0;
          }

          //estos estislo son los mismos qeu los de wishlist.scss
          .perfume-row {
            border: 1px solid $shady-lady;
            margin: 2px;
            padding: 15px;

            .mobile & {
              margin: 0px;
            }

            &:not(:last-child) {
              margin-bottom: 10px;
            }
          }
        }

        .button {}
      }

      .explanation-cover {
        align-items: center;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;

        &.cover-transition-enter {
          opacity: 0;
        }

        &.cover-transition-enter-active {
          opacity: 1;
          transition: opacity .5s;
        }

        &.cover-transition-exit {
          opacity: 1;
        }

        &.cover-transition-exit-active {
          opacity: 0;
          transition: opacity .5s;
        }

        .button {
          .mobile & {
            margin: 20px;
            left: 0;
            position: absolute;
            bottom: 0;
            width: calc(100% - 40px);
          }
        }

        .explanation-content {
          @include font-size(26, 36);

          font-weight: 400;
          // left: 50%;
          margin-bottom: 40px;
          // position: absolute;
          text-align: center;
          // top: 50%;
          // transform: translate(-50%, -50%);
          // width: 80%;

          .mobile & {
            @include font-size(18, 28);

            width: 90%;
          }
        }
      }
    }

    &.concepts-question {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-bottom: 40px;
      padding-left: 100px;
      padding-right: 100px;
      padding-top: 40px;

      .mobile & {
        display: flex;
        flex-direction: column;
        padding: 20px;
      }

      .title {
        flex: 0 0 30px;
        font-weight: 400;

        // height: 30px;
        line-height: 30px;
        margin-bottom: 40px;
        text-align: left;
        transition: font-size .5s ease-in-out;

        .mobile & {
          flex: 0 0 auto;
          font-size: 14px !important;
          height: auto;
          line-height: 1.25;
          margin-bottom: 0;
        }

        &.small {
          font-size: 16px;
        }

        &.large {
          font-size: 22px;
        }
      }

      .subtitle {
        @include font-size(12, 14);

        display: none;
        font-weight: 100;
        margin-bottom: 20px;
      }

      .concepts-wrapper {
        flex: 1 1 0;
        overflow-x: hidden;
        overflow-y: auto;

        .mobile & {
          flex: 1 1 0;
          margin-bottom: 20px;
          margin-top: 20px;
        }
      }

      .button {
        width: 240px;
        position: absolute;
        bottom: 40px;

        &.next-button {
          right: 40px;

          .mobile & {
            position: static;
            width: 100%;
            left: auto;
            right: auto;
          }
        }

        &.skip-button {
          right: 40px;
        }
      }
    }
  }