@font-face {
  font-family: 'Avenir';
  src: url("AvenirNextW04-Bold.eot");
  src: url("AvenirNextW04-Bold.eot?#iefix") format("embedded-opentype"), url("AvenirNextW04-Bold.woff2") format("woff2"), url("AvenirNextW04-Bold.woff") format("woff"), url("AvenirNextW04-Bold.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url("AvenirNextW04-Demi.eot");
  src: url("AvenirNextW04-Demi.eot?#iefix") format("embedded-opentype"), url("AvenirNextW04-Demi.woff2") format("woff2"), url("AvenirNextW04-Demi.woff") format("woff"), url("AvenirNextW04-Demi.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url("AvenirNextW04-Regular.eot");
  src: url("AvenirNextW04-Regular.eot?#iefix") format("embedded-opentype"), url("AvenirNextW04-Regular.woff2") format("woff2"), url("AvenirNextW04-Regular.woff") format("woff"), url("AvenirNextW04-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url("AvenirNextW10-UltraLight.eot");
  src: url("AvenirNextW10-UltraLight.eot?#iefix") format("embedded-opentype"), url("AvenirNextW10-UltraLight.woff2") format("woff2"), url("AvenirNextW10-UltraLight.woff") format("woff"), url("AvenirNextW10-UltraLight.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}
