$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.app-header {
  align-items: center;
  background-color: #fff;
  border-bottom: 2px solid $gallery;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  z-index: 3;

  &.empty {
    border-bottom-color: transparent;

    .nav-buttons {
      display: none;
    }

    .shortcuts {
      // display: none;
      .shortcut-btn {
        display: none;
      }
    }
  }

  a {
    text-decoration: none;
  }

  .logo_wrapper {}

  .go-to-nocibe {
    color: black;
    font-size: 0.75rem;
    span {
      text-decoration: underline;
    }
  }

  .logo {
    background-image: url("../../assets/images/logo.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    cursor: pointer;
    display: block;
    height: 50px;
    left: 50%;
    width: 140px;
  }

  .top-header {
    align-items: center;
    display: flex;
    align-items: center;
    height: 60px;
    padding-left: 30px;
    padding-right: 30px;
    width: 100%;
    border-bottom: 1px solid #eee;

    .mobile & {
      margin-bottom: 0;
    }
  }

  .nav-buttons {
    align-items: center;
    display: flex;
    flex: 1 1 0;
    height: 58px;
    justify-content: center;

    .reffed-link {
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;

      &.selected {
        background: #F7F7F7;
        border-color: #eee;

        .nav-button {
          color: $primary-theme-color;
        }
      }

      .nav-button {
        @include font-size(12);

        align-items: center;
        color: $base-font-color;
        display: flex;
        height: 100%;
        padding: 0 20px;
        text-align: center;
        text-decoration: none;

        > span {
          @include first-letter-case;
        }
      }
    }

    .underline {
      background-color: $primary-theme-color;
      bottom: 0;
      height: 4px;
      position: absolute;
      transition: width .5s, left .3s;
    }
  }

  .shortcuts {
    @include font-size(12);

    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;

    .shortcut-btn {
      &:not(:last-child)  {
        margin-right: 20px;
      }
    }

    .wish-list-button {
      align-items: center;
      cursor: pointer;
      display: flex;
      justify-content: center;
      width: 145px;

      &:hover {
        text-decoration: underline;
      }
    }

    .wish-list-icon {
      background-image: url("../../assets/icons/icon-hearts.svg");
      background-position: center;
      background-position: right;
      background-repeat: no-repeat;
      background-size: contain;
      height: 20px;
      margin-right: 3px;
      width: 20px;
      transition: transform 0.25s ease-in-out;

      &.filled {
        background-image: url("../../assets/icons/icon-hearts-full.svg");
      }

      &.flashing {
        transform: scale(1.5);
      }
    }

    .wish-list-text {
      .mobile & {
        $border-radius: 1px;
        $height: 12px;

        @include font-size(6, 6);

        align-items: center;
        background-color: #fff;
        border-radius: 50%;
        border: $border-radius solid $primary-theme-color;
        display: flex;
        height: $height;
        justify-content: center;
        margin-left: -6px;
        margin-top: -6px;
        position: relative;
        width: $height;

        &:before {
          border-radius: 50%;
          border: 1px solid #fff;
          content: "";
          display: block;
          height: $height + $border-radius * 2;
          left: -($border-radius * 2);
          position: absolute;
          top: -($border-radius * 2);
          width: $height + $border-radius * 2;
        }
      }
    }

    .save-button {
      // display: none !important;
      border-radius: 20px;
      border: 1px solid $base-font-color;
      cursor: pointer;
      padding: 10px 15px;
      text-transform: uppercase;
      margin: 0 10px;

      @include font-size(10, 12);

      &:hover,
      &:active {
        background-color: $base-font-color;
        color: #fff;
      }
    }

    .howto-icon {
      // VERY BULLSHIT, needs icon image...
      border-radius: 50%;
      border: 1px solid #49494B;
      color: #000;
      font-size: 1rem;
      height: 30px;
      line-height: 1.8rem;
      margin: 10px;
      text-align: center;
      width: 30px;
    }
  }

  .warn-popup,
  .warn-wish-popup {
    align-items: center;
    background-color: $primary-theme-color;
    box-shadow: 0 0 30px rgba($shady-lady, 0.3);
    display: flex;
    justify-content: center;
    padding: 10px;
    position: absolute;
    top: 72px;
    z-index: 2;

    .mobile & {
      left: 10px !important;
      top: 50px;
      width: 95vw;

      &:after {
        left: 10px;
      }
    }

    &:after {
      border-bottom: 5px solid $primary-theme-color;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      content: "";
      height: 0;
      left: 50%;
      position: absolute;
      top: -5px;
      width: 0;
    }

    > span {
      @include first-letter-case;
      @include font-size(12, 14);

      color: #fff;

      .mobile & {
        @include font-size(10, 12);
      }
    }
  }
}

.mobile {
  .app-header {
    position: inherit !important;
    margin: 0;
    display: block;

    .app-header-top {
      display: flex;
      padding: 10px;
      align-items: center;
      height: 60px;
      .burger {
        background-image: url("../../assets/icons/icon-burger.svg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: contain;
        cursor: pointer;
        height: 30px;
        width: 30px;
      }
  
      .logo-wrapper {
        align-items: center;
        display: flex;
        flex: 1 1 0;
        height: 100%;
        justify-content: center;
      }
  
      .logo {
        height: 100%;
        left: 0;
        position: relative;
        transform: none;
        width: 80px;
      }
    }

    .burger-wrapper {
      flex: 0 0 20%;
    }


    .shortcuts {
      flex: 0 0 20%;

      .wish-list-button {
        width: auto;
      }
    }

    .panel-wrapper {
      left: 0;
      pointer-events: none;
      top: 0;
      width: 100%;
      z-index: 1;
      visibility: hidden;
      height: 0;

      &.open {
        visibility: visible;
        height: auto;
      }

      // &.open {
      //   .panel {
      //     left: 0;
      //   }

      //   .panel-bkg {
      //     opacity: 1;
      //     pointer-events: all;
      //   }

      //   .close-btn {
      //     pointer-events: all;
      //     opacity: 1;
      //     cursor: pointer;
      //   }
      // }

      .panel {
        background-color: #fff;
        display: flex;
        flex-direction: column;
        border-top: 1px solid lightgray;
        pointer-events: all;
        transition: left .5s;
        width: 100%;

        .reffed-link {

          &.selected {
            .nav-button {
              color: $primary-theme-color;
            }
          }

        }

        .nav-button {
          @include ellipsis(100%);
          @include font-size(17, 20);
          font-weight: 400;

          color: #382F2D;
          display: flex;
          justify-content: center;
          align-items: center;
          text-align: center;
          height: 60px;
          border-bottom: 1px solid lightgray; 
          position: relative;
          text-decoration: none;

          // &:after {
          //   background-image: url("../../assets/icons/icon-caret-right.svg");
          //   background-position: center center;
          //   background-repeat: no-repeat;
          //   background-size: contain;
          //   content: "";
          //   display: block;
          //   height: 15px;
          //   position: absolute;
          //   right: 0;
          //   top: 50%;
          //   transform: translateY(-50%);
          //   width: 15px;
          // }
        }
        
        .save-button {
          color: $primary-theme-color;
          margin-top: auto;
          padding: 10px;
          text-align: center;
        }
      }

      .panel-bkg {
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        transition: opacity .9s;
        width: 100%;
      }

      .close-btn {
        @include font-size(40, 40);

        align-items: center;
        color: #fff;
        display: flex;
        font-weight: 400;
        height: 20px;
        justify-content: center;
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: calc(7.5vw - 10px);
        top: 20px;
        transition: opacity .7s;
        width: 20px;
      }
    }
  }
}
