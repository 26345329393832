$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.how-container {
  // scroll-snap-type: y mandatory;
  overflow-x: hidden;
  overflow-y: auto;
  background: #fff;

  a {
    color: $primary-theme-color;
  }

  .left {
    padding-right: 20px;
  }

  .right {
    padding-left: 20px;
  }

  .title {
    @include font-size(34, 48);

    font-weight: 400;
  }

  .step-title {
    @include font-size(18, 30);
    @include letter-spacing(25);

    font-weight: 400;
    margin-bottom: 20px;
  }

  .sub-title {
    @include font-size(14, 24);

    margin-bottom: 20px;

    &:after {
      background-color: #000;
      content: "";
      display: block;
      height: 1px;
      width: 75px;
      margin-top: 10px;
    }
  }

  .step-text,
  .sub-text,
  .text {
    @include font-size(14, 24);
  }

  .step-number {
    @include font-size(16);

    margin-bottom: 10px;

    &:after {
      background-color: #000;
      content: "";
      display: inline-block;
      height: 1px;
      margin-left: 5px;
      width: 75px;
    }
  }

  .how-content {
    min-height: 100%;
    position: relative;

    .mobile & {
      overflow: hidden;
    }

    .background {
      background-color: #fff;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      position: absolute;

      &.top {
        height: 100vh;
        left: -45vw;
        top: -20vh;
        width: 100vw;
      }

      &.middle {
        height: 100vh;
        right: -40vw;
        top: 50vw;
        width: 100vw;

        .mobile & {
          bottom: -15vh;
          height: 60vh;
          left: -10vw;
          right: auto;
          top: auto;
          transform: rotate(115deg);
        }
      }

      &.bottom {
        background-position: center 25vh;
        bottom: 0;
        height: 100vh;
        left: -25vw;
        width: 100vw;

        .mobile & {
          display: none;
        }
      }
    }

    .how-section {
      // scroll-snap-align: start;
      align-items: center;
      display: flex;
      justify-content: center;
      margin: 40px 0;
      position: relative;
      width: 100%;

      .centered-content {
        align-items: center;
        display: flex;
        justify-content: center;
        text-align: left;
        text-shadow: 0 1px 20px #fff;
        width: 60%;

        @media only screen and (min-width: 1441px) {
          width: 50%;
        }
      }

      &.purpose {
        margin-bottom: 80px;
        margin-top: 80px;

        .centered-content {
          flex-direction: column;
        }

        .title {
          align-self: flex-start;

          &:after {
            background-color: rgba(#000, 0.25);
            content: "";
            display: block;
            height: 1px;
            margin-bottom: 1em;
            margin-left: auto;
            margin-right: -10vw;
            margin-top: -0.7em;
            width: 10vw;
          }
        }

        .steps {
          align-self: flex-end;
        }

        .left {
          margin-bottom: -1.45em;
          width: 50%;
          padding-right: 10vw;
        }

        .right {
          width: 50%;
        }
      }

      &.image-and-text {
        // .centered-content {
        //   align-items: flex-start;
        // }

        &:not(.overflow) {
          .centered-content {
            border-bottom: 1px solid rgba(#000, 0.25);
            padding-bottom: 40px;
          }
        }

        &.align-left {}

        &.align-right {
          &.overflow {
            .centered-content {
              width: 100%;
              margin-left: 20%;

              @media only screen and (min-width: 1441px) {
                margin-left: 25%;
              }
            }

            .right {
              width: 100%;
            }

            .image {
              &.large {
                height: 70vh;
                background-position: center center;
              }
            }
          }
        }

        .title {
          @include font-size(22, 32);

          font-weight: bold;
          margin-bottom: 0.5em;
        }

        .left,
        .right {
          width: 50%;
        }

        .left {
          .image {
            background-position: left center;
          }
        }

        .right {
          .image {
            background-position: right center;
          }
        }

        .image {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
          height: 40vh;

          &.large {
            height: 60vh;
          }
        }
      }

      &.quads {
        margin-bottom: 0;
        margin-top: 80px;

        .centered-content {
          flex-direction: column;
        }

        .quad {
          width: 50%;
          margin-bottom: 40px;

          &.title-quad {
            @include font-size(34, 48);

            align-self: flex-start;
            margin-bottom: -1em;
            padding-right: 5vw;

            &:after {
              background-color: rgba(#000, 0.25);
              content: "";
              display: block;
              height: 1px;
              margin-bottom: 1em;
              margin-left: auto;
              margin-right: -5vw;
              margin-top: -0.7em;
              width: 5vw;
            }
          }

          &.text-quad {
            align-self: flex-end;
            margin-top: -1em;
            padding-left: 20px;
            padding-right: 40px;
            position: relative;

            // &:before {
            //   background-color: rgba(#000, 0.25);
            //   content: "";
            //   display: block;
            //   height: 100%;
            //   left: 0;
            //   position: absolute;
            //   top: 0;
            //   width: 1px;
            // }

            .title {
              @include font-size(22, 32);

              margin-bottom: 10px;
            }

            .content {
              @include font-size(14, 24);
            }
          }
        }
      }
    }
  }

  .opacity-layer {
    background-color: rgba(#fff, 0.7);
    display: none;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .mobile & {
    .opacity-layer {
      display: block;
    }

    .how-section {
      margin: 20px 0;

      &.purpose {
        margin-bottom: 20px;
        margin-top: 20px;

        .title {
          &:after {
            display: none;
          }
        }

        .left,
        .right {
          margin-bottom: 0.5em;
          padding: 0;
          width: 100%;
        }
      }

      &.image-and-text {
        &:not(.overflow) {
          .centered-content {
            padding-bottom: 20px;
          }
        }

        &.align-right {
          &.overflow {
            .centered-content {
              margin-left: 0;
            }
          }

          .left {
            margin-bottom: 20px;
          }
        }

        &.align-left {
          .left {
            margin-bottom: 20px;
          }
        }

        .image {
          background-position: center center !important;

          &.large {
            height: 50vh !important;
          }
        }

        .left,
        .right {
          padding: 0;
          width: 100%;
        }
      }

      &.quads {
        margin-top: 20px;

        .quad {
          width: 100%;

          &.title-quad {
            margin-bottom: 0.5em;

            &:after {
              display: none;
            }
          }

          &.text-quad {
            padding: 0;
            margin-top: 0;
          }
        }
      }

      &.qa {
        .row {
          .row-title,
          .row-content {
            flex: 0 0 50%;
          }
        }
      }

      .centered-content {
        flex-direction: column;
        width: 90% !important;
      }
    }

    .how-content {
      .background {
        &.bottom {
          background-position: center 50vh;
          bottom: 0;
          left: 0;
        }
      }
    }
  }
}
