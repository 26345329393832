$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.mini-donut-wrapper {
  padding: 5px;
  position: absolute;
  right: 5px;
  top: 5px;

  &:before {
    background-color: #fff;
    border-radius: 4px;
    content: "";
    height: 100%;
    left: 0;
    opacity: 0.5;
    position: absolute;
    top: 0;
    width: 100%;
  }

  .mini-donut {
    height: 35px;
    left: 50%;
    opacity: 0.8;
    position: relative;
    transform: translate(-50%, 0);
    width: 35px;
    margin-bottom: 5px;

    .outter-ring,
    .inner-ring,
    .center {
      border-radius: 100%;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    .outter-ring {
      height: 100%;
      width: 100%;
    }

    .inner-ring {
      border: solid 1px rgba(255, 255, 255, 0.4);
      height: 60%;
      width: 60%;
    }

    .center {
      background-color: #fff;
      height: 30%;
      width: 30%;
    }
  }

  .labels {
    max-width: 75px;
    opacity: 0.8;
    text-align: center;

    .main {
      @include font-size(9, 11);

      font-weight: 400;
      margin-bottom: 2px;
    }

    .secondary {
      @include font-size(8, 10);
    }
  }
}
