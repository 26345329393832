$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.filters-wrapper {
  display: flex;
  position: relative;

  .filters {
    background-color: #F3F1F1;
    display: flex;
    padding: 10px;
    flex: 1 1 auto;

    //SHan de canviar tots a "react-select__whatevers"...
    .react-select { //Aquest ja lhe canviat jo
      flex: 1 1 auto;
      width: 300px;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }

    .react-select__control {
      border: none;
      border-radius: 0;
      &.react-select__control--is-focused {
        border-color: $primary-theme-color !important;
        box-shadow: 0 0 1px $primary-theme-color !important;
      }

      .react-select__value-container {
        @include font-size(13);

        .react-select__placeholder {
          @include ellipsis(100%);

          width: calc(100% - 16px);
        }
      }

      .react-select__indicator-separator {
        opacity: 0;
      }
    }

    .react-select__menu {
      .react-select__menu-list {
        .react-select__option {
          @include font-size(13);

          background-color: #fff;

          &.react-select__option--is-selected {
            background-color: $primary-theme-color;
            color: #fff;
          }

          &:hover {
            &:not(.react-select__option--is-selected) {
              background-color: rgba($primary-theme-color, 0.5);
              color: #fff;
            }
          }
        }
      }
    }
  }

  .mobile & {
    .filters {
      flex: 1 1 0;
      margin: 0;
      position: relative;

      &.collapsed {
        // Guarrero pero efectivo... no transitions though..
        height: 0;
        padding: 0;
        overflow: hidden;
      }
    }

    .collapse-button,
    .expand-button {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 25px;
      position: absolute;
      right: 10px;
      top: -5px;
      transform: translate(0, -100%);
      width: 25px;
    }

    .collapse-button {
      background-image: url("../../assets/icons/icon-close.svg");
      background-size: 18px;
    }

    .expand-button {
      background-image: url("../../assets/icons/icon-filter.svg");
    }
  }
}
