$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.perfume-and-panel {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  left: 0px;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 3; //Creo qeu vale para todos... (necesario para el "recomendations" que tiene el sticky i tal...)

  .mobile & {
    display: block;
    position: fixed;
    z-index: 3;
  }

  .overlay {
    background: rgba(245, 245, 245, 0.5);
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .left-perfume-wrapper {
    width: 100%;
    position: relative;

    // CONFLICTOS EN MOBILE...
    pointer-events: none;

    .mobile & {
      flex: 1 1 auto;
    }

    .perfume-full-view {
      pointer-events: all;
    }
  }

  .right-panel-wrapper {
    flex: 0 0 20%;
    position: relative;
    z-index: 1;
    transition: all .3s ease-in-out;
    transform: translate(0, 0);

    &.expanded {
      .mobile & {
        height: 100%;
        // .panel {
        //   top: calc(-100vh + 50px);
        // }
      }
    }

    .mobile & {
      bottom: 0;
      flex: 0 0 50px;
      height: 50px;
      width: 100%;
      position: absolute;

      &.no-panel {
        display: none;
      }

      .panel {
        height: 100%;
        // transition: top .4s ease-in-out;

        .panel-teaser {
          @include font-size(13, 20);

          align-items: center;
          color: $primary-theme-color;
          display: flex;
          height: 50px;
          justify-content: center;
        }

        .panel-header-wrapper {
          background-color: #fff;
          position: sticky;
          top: 0;
          z-index: 1;

          .close-button {
            background-image: url("../../assets/icons/icon-close.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
            height: 20px;
            position: absolute;
            right: 15px;
            top: 15px;
            width: 20px;
          }

          .reco-description {
            margin-bottom: 20px;
            padding-bottom: 10px;
            padding-left: 20px;
            padding-right: 40px;
            padding-top: 40px;
          }
        }
      }
    }

    .panel {
      background-color: $alabaster;
      display: flex;
      flex-direction: column;
      height: 100%;
      overflow-y: scroll; // con el resizeme y auto, hacia un flicker infernal...
      padding: 20px;
      position: absolute;
      width: 100%;
      top: 0;

      .mobile & {
        display: block;
        overflow-y: auto;
        padding: 0;
      }

      .title {
        @include first-letter-case;
        @include font-size(16, 24);

        margin-bottom: 10px;

        .mobile & {
          // @include ellipsis(100%);
          @include font-size(12, 20);

          margin-left: 20px;
          margin-right: 20px;
        }
      }

      .subtitle {
        @include first-letter-case;
        @include font-size(12, 16);

        margin-bottom: 10px;
        margin-left: 10px
      }

      .ingredients-list {
        // border-bottom: solid 1px $shady-lady;
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 20px;
        margin-left: 20px;
        margin-right: 20px;
        padding-bottom: 10px;

        .mobile & {
          border-bottom: none;
          flex-wrap: nowrap;
          flex: 1 1 0;
          margin-bottom: 10px;
          overflow-x: auto;
          overflow-y: hidden;
        }

        .ing-item {
          $margin: 10px;
          $width: percentage(1 / 2);

          margin: $margin;
          width: calc(#{$width} - #{$margin} * 2);

          .mobile & {
            $margin: 5px;
            $width: percentage(1 / 4);

            display: flex;
            flex-direction: column;
            flex: 0 0 calc(#{$width} - #{$margin} * 2);
            margin: $margin;
            width: calc(#{$width} - #{$margin} * 2);
          }

          .image {
            margin-bottom: 10px;

            .mobile & {
              margin-bottom: 0;
            }
          }

          .label {
            @include font-size(12, 14);

            .mobile & {
              @include font-size(8, 10);
            }
          }
        }
      }

      .ingredients-text-list {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 20px;
        padding-bottom: 10px;
        border-bottom: solid 1px $shady-lady;

        .ing-text {
          @include font-size(10, 12);
          @include letter-spacing(25);

          margin-bottom: 10px;
          margin-left: 10px;
        }
      }

      .donut-column {
        background: none;
        text-align: left;
        justify-content: flex-start;

        .title {
          text-align: center;
          width: 100%;
        }

        .mobile & {
          display: flex;
          height: auto;
          justify-content: center;

          .title {
            @include font-size(16, 20);

            margin: 0 20px;
          }

          .description-column {
            flex: 1 1 0;
            margin-right: 20px;
            width: 50%;
          }

          .profile-description {
            .main {
              @include font-size(10, 12);

              text-align: left;
            }
          }
        }
      }
    }
  }

  &.perfume-and-panel-enter {
    .left-perfume-wrapper, .overlay {
      opacity: 0;
    }

    .right-panel-wrapper {
      transform: translate(100%, 0);
    }
  }

  &.perfume-and-panel-enter-active {
    .left-perfume-wrapper {
      opacity: 1;
      transition: opacity .5s ease-in-out;
    }

    .overlay {
      opacity: 1;
      transition: opacity 1s ease-in-out;
    }

    .right-panel-wrapper {
      transition: transform .3s ease-in-out;
      transition-delay: .3s;
      transform: translate(0, 0);
    }
  }

  &.perfume-and-panel-exit {
    .left-perfume-wrapper, .overlay {
      opacity: 1;
    }

    .right-panel-wrapper {
      transform: translate(0, 0);
    }
  }

  &.perfume-and-panel-exit-active {
    .left-perfume-wrapper {
      opacity: 0;
      transition: opacity .5s ease-in-out;
    }

    .overlay {
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }

    .right-panel-wrapper {
      transition: transform .5s ease-in-out;
      transform: translate(100%, 0);

      .mobile & {
        transform: translate(0, 100%);
      }
    }
  }
}
