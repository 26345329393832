$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
$bar-height: 5px;

.section.wizard {
  display: flex;
  flex-direction: column;

  .wizard-header {
    background-color: #fff;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    left: 0;
    top: 0;
    width: 100%;
    height: 45px;
    z-index: 1;
    position: relative;

    .mobile & {
      height: 20px;
    }

    .step {
      @include font-size(14);

      color: $razzmatazz;
      display: flex;
      flex: 1 1 25%;
      justify-content: flex-start;
      font-weight: 600;
      margin: 5px 20px;

      .mobile & {
        @include font-size(10, 10);

        margin: 0 10px 5px 10px;
      }

      &.selected {
        color: $primary-theme-color;
      }

      &.disabled {
        opacity: 0.5;
      }
    }

    .bar {
      background-color: $primary-theme-color;
      bottom: -$bar-height;
      height: $bar-height;
      left: 0;
      position: absolute;
      transition: width 1s ease-in-out;
      width: 0%;
    }
  }

  .wizard-content {
    display: flex;
    flex: 1 1 0;
    height: 100%;
    overflow: hidden; // :(
    width: 100%;

    .left {
      background-color: #fff;
      flex: 1 1 0;

      .background {
        //leave as default!
        background-image: url("../../../assets/images/bg-compo-3.jpeg");
        background-position: left center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        opacity: 1;
        position: absolute;
        transition: opacity 1s;
        width: 100%;

        &.bkg-1 {
          background-image: url("../../../assets/images/bg-compo-3.jpeg");
        }

        &.bkg-2 {
          background-image: url("../../../assets/images/bg-compo-4.jpeg");
          left: 0vw;
          width: 160%;
        }

        &.bkg-3 {
          background-image: url("../../../assets/images/bg-compo-5.jpeg");
        }

        // &:before {
        //   background-color: rgba(#fff, 0.85);
        //   content: "";
        //   height: 100%;
        //   position: absolute;
        //   width: 100%;
        // }

        .mobile & {
          left: -15vw;
          width: 160%;
          
          &.bkg-2 {
            left: -60vw;
            width: 160%;
          }
        }

        &.white {
          opacity: 0;
        }
      }

      .questions-transition-group {
        width: 100%;
        height: 100%;
        display: flex;
        position: relative;
      }

      .questions-wrapper {
        width: 100%;
        height: 100%;
        border-top: $bar-height solid rgba(0, 0, 0, 0.1);
        background-color: rgba(255, 255, 255, 0.6);
        position: relative;

        // .mobile & {
        //   height: calc(100vh - 80px);
        // }

        &.question-transition-enter {
          left: 100%;
        }

        &.question-transition-enter-active {
          left: 0;
          transition: left 1.2s;
        }

        &.question-transition-exit {
          left: 0;
        }

        &.question-transition-exit-active {
          left: -100%;
          transition: left 1.2s;
        }

        .question {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;

          

          &.buttons-question {
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 40px;
            box-sizing: border-box;
            
            flex-direction: column;
            height: 50%;
            width: 100%;
            position: relative;

            .title {
              @include font-size(16, 21);
              position: static;
              font-weight: 600;
              transform: none;

              .mobile & {
                @include font-size(20, 24);

                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                left: auto;
                margin: 20px;
                position: relative;
                text-align: center;
                top: auto;
                transform: none;
              }
            }

            .options {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              width: 50%;
              flex-grow: 0;
              flex-shrink: 0;

              .mobile & {
                flex-direction: column;
                justify-content: flex-start;
                width: 70%;
              }

              .option {
                align-items: center;
                background-color: rgba(#fff, 0.9);
                cursor: pointer;
                justify-content: center;
                text-align: center;
                width: 50%;
                height: 57px;
                border: 1px solid black;
                opacity: 1;
                margin-right: 0px;
                margin-bottom: 20px;

                .mobile & {
                  flex: auto;
                  width: 100%;

                  &:not(:last-child) {
                    margin-bottom: 20px;
                    margin-right: 0;
                  }
                }

                &:active {
                  background-color: #312a28;
                  color: #ffb3c7;
                }
                &:hover {
                  background-color: #312a28;
                  color: #ffb3c7;
                }

                &.active {
                  background-color: #312a28;
                  color: #ffb3c7;
                }

                &.down {
                  opacity: 1;
                  background-color: #312a28;
                }

                &.right-answer {
                  background-color: #312a28;
                  color: #ffb3c7;
                }

                .icon {
                  background-position: center center;
                  background-repeat: no-repeat;
                  background-size: contain;
                  height: 50px;
                  margin-bottom: 20px;
                  width: 100px;
                }

                .label {
                  @include font-size(16, 25);
                  @include letter-spacing(50);
                  font-weight: 600;

                  width: 60%;
                  text-transform: uppercase;

                  .mobile & {
                    @include font-size(13, 17);
                  }
                }
              }
            }
          }

          &.perfumes-question {
            background: #fff;
            display: flex;

            .perfumes-left {
              display: flex;
              flex-direction: column;
              flex: 1 1 0;
              overflow: hidden;
              padding-bottom: 20px;
              padding-left: 20px;
              padding-top: 40px;
              padding-right: 7.5%;
              position: relative;

              @media (max-width: 1140px) {
                padding-right: 20px;
                padding-left: 20px;
              }

              .mobile & {
                line-height: 1;
              }

              .title {
                @include first-letter-case;
                @include font-size(16, 16);

                flex: 0 0 30px;
                font-weight: 600;
                height: 30px;
                // line-height: 30px;
                margin-bottom: 8px;
                text-align: left;

                .mobile & {
                  // flex: auto;
                  font-size: 14px !important;

                  // height: auto;
                  line-height: 1.2;
                  margin-bottom: 2%;
                }

              }

              .search-bar {
                $margin: 15px;

                .mobile & {
                  $margin: 10px;
                }

                border-color: $primary-theme-color;
                border-radius: $margin * 2;
                margin-bottom: 20px;
                width: 100%;

                .input-wrapper {
                  input[type="text"] {
                    @include font-size(18, 22);
                    @include letter-spacing(20);

                    margin: $margin ($margin * 2);
                    outline: none;
                    padding: 0;

                    .mobile & {
                      @include font-size(12, 12);
                      @include letter-spacing(10);
                    }
                  }
                }

                .icon {
                  margin-right: $margin * 2;
                }
              }

              .results-wrapper {
                position: relative;
                flex: 1 1 0;
                flex-direction: column;
                overflow: hidden;
                display: flex;
                min-height: 30vh;
                
                .results-count {
                  font-size: 17px;
                  margin-left: 10px;
                }

                .loading,
                .no-results {
                  background: transparent;
                }

                .reminder-message {
                  .text {
                    @include font-size(18, 22);

                    font-weight: 400;

                    &:before {
                      background-image: url("../../../assets/icons/icon-arrow-up.svg");
                      background-position: center center;
                      background-repeat: no-repeat;
                      background-size: contain;
                      bottom: 50%;
                      content: "";
                      height: 50px;
                      left: 50%;
                      margin-bottom: 20px;
                      position: absolute;
                      transform: translate(-50%, -50%);
                      width: 50px;
                    }
                  }
                }
              }
            }

            .perfumes-right-wrapper {
              display: flex;

              // CREO QUE ARREGLA lo del "etape 2/4 wrong flex" (1)
              width: 30vw;
              max-width: 500px;

              .mobile & {
                width: 100%;
                max-width: 100%;
              }
            }

            .perfumes-right {
              background-color: #fff;
              display: flex;
              flex-direction: column;
              opacity: 1;
              padding: 40px;
              transition: opacity .5s;

              // CREO QUE ARREGLA lo del "etape 2/4 wrong flex" (1)
              width: 100%;

              @media (max-width: 1024px) {
                padding-left: 20px;
                padding-right: 20px;

              }

              .mobile & {
                height: 100%;
                max-width: 100%;
                padding: 20px;
                position: absolute;
                right: 0;
                transition: right .6s;
                width: 85vw;

                &.hidden {
                  right: -100%;
                }
              }

              &.hidden {
                opacity: 0;
              }

              .title {
                @include font-size(12);

                font-weight: 400;
                margin-bottom: 8px;
                line-height: 30px;
              }

              .selection {
                flex: 1 1 0;
                margin-bottom: 20px;
                overflow-y: auto;

                .mobile & {
                  margin-bottom: 0;
                }

                //estos estislo son los mismos qeu los de wishlist.scss
                .perfume-row {
                  margin: 2px;
                  padding: 15px;

                  .mobile & {
                    margin: 0px;
                  }

                  &:not(:last-child) {
                    margin-bottom: 10px;
                  }
                }
              }

              .button {}
            }

            .explanation-cover {
              align-items: center;
              background-color: #fff;
              display: flex;
              flex-direction: column;
              height: 100%;
              justify-content: center;
              left: 0;
              position: absolute;
              top: 0;
              width: 100%;

              &.cover-transition-enter {
                opacity: 0;
              }

              &.cover-transition-enter-active {
                opacity: 1;
                transition: opacity .5s;
              }

              &.cover-transition-exit {
                opacity: 1;
              }

              &.cover-transition-exit-active {
                opacity: 0;
                transition: opacity .5s;
              }

              .button {
                .mobile & {
                  margin: 20px;
                  left: 0;
                  position: absolute;
                  bottom: 0;
                  width: calc(100% - 40px);
                }
              }

              .explanation-content {
                @include font-size(26, 36);

                font-weight: 400;
                // left: 50%;
                margin-bottom: 40px;
                // position: absolute;
                text-align: center;
                // top: 50%;
                // transform: translate(-50%, -50%);
                // width: 80%;

                .mobile & {
                  @include font-size(18, 28);

                  width: 90%;
                }
              }
            }
          }

          &.concepts-question {
            display: flex;
            align-items: center;
            flex-direction: column;
            overflow: hidden;
            padding-bottom: 40px;
            padding-left: 100px;
            padding-right: 100px;
            padding-top: 40px;

            .mobile & {
              display: flex;
              flex-direction: column;
              padding: 20px;
              padding-top: 18vh;
            }

            .title {
              flex: 0 0 30px;
              font-weight: 600;
              @include font-size(16, 21);
              text-align: center !important;
              width: 50%;

              // height: 30px;
              line-height: 30px;
              margin-bottom: 40px;
              text-align: left;
              transition: font-size .5s ease-in-out;

              .mobile & {
                width: 100%;
                font-size: 14px !important;
                height: auto;
                line-height: 1.25;
                margin-bottom: 0;
              }

            }

            .subtitle {
              @include font-size(12, 14);

              display: none;
              font-weight: 100;
              margin-bottom: 20px;
            }

            .concepts-wrapper {
              flex: 1 1 0;
              overflow-x: hidden;
              overflow-y: auto;

              .mobile & {
                margin-bottom: 20px;
                width: 100%;
                height: auto;
                margin-top: 20px;
              }
            }

            .button {
              position: static;
              width: 240px;

              .mobile & {
                position: static;
                width: 100%;
                left: auto;
                right: auto;
              }
            }
          }
        }
      }
    }

    .right {
      flex: 0;
      transition: flex .8s ease-in-out;
      overflow: hidden;
      z-index: 1;
      background: #fff;
      min-width: 300px;

      .mobile & {
        display: none !important;
      }

      &.visible {
        flex: 0 350px;
      }

      .cut-wrapper {
        height: 100%;

        .donut-column {
          padding: 20px;
        }
      }
    }
  }
}

.mobile {
  .section.wizard {
    .mobile-actions {
      border-top: 1px solid rgba(#000, 0.15);
      display: flex;
      margin-top: 20px;
      padding-top: 20px;

      .button {
        flex: 1 1 50%;

        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .perfumes-right-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      pointer-events: none;
      overflow: hidden;

      .perfumes-right {
        pointer-events: all;

        &.hidden {
          opacity: 1 !important; //ya se esconde con "right"
       
        }
      }

      .panel-background {
        background: rgba(0, 0, 0, 0.7);
        height: 100%;
        left: 0;
        pointer-events: all;
        position: absolute;
        top: 0;
        transition: opacity .6s;
        width: 100%;

        &.hidden {
          opacity: 0;
          pointer-events: none;
        }

        .close-button {
          @include font-size(40, 40);

          align-items: center;
          color: #fff;
          display: flex;
          font-weight: 400;
          height: 20px;
          justify-content: center;
          left: calc(7.5vw - 10px);
          position: absolute;
          top: 20px;
          width: 20px;
        }
      }
    }

    .perfume-row {
      .row-top {
        // flex-direction: row-reverse;
        flex-wrap: nowrap;

        .remove-btn {
          margin: 0;
        }
      }
    }
  }
}
