$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.nocibe-button {
  @include font-size(10, 12);
  @include letter-spacing(50);

  align-items: center;
  background-color: $primary-theme-color;
  border: 1px solid $primary-theme-color;
  color: #fff;
  display: flex;
  font-weight: 600;
  font-size: 14px;
  min-width: 160px;
  justify-content: center;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;

  .mobile & {
    padding: 10px;
  }

  &.disabled {
    background-color: $shady-lady;
    border: 1px solid $shady-lady;
    opacity: 0.5;
    pointer-events: none;
  }

  .label {
    margin-top: 0.2em;
  }

  &.secondary {
    border: 1px solid #382F2D;
    background: none;
    color: #382F2D;

    &:hover {
      background-color: #312a28;
      color: #ffb3c7;
    }

    &.active {
      background-color: #312a28;
      color: #ffb3c7;
    }
  }

}

.nocibe-text-button {
  @include font-size(10, 12);
  @include letter-spacing(50);

  color: $primary-theme-color;
  padding: 15px 0;
  text-align: center;
  text-decoration: underline;
  text-transform: uppercase;

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.nocibe-light-button {
  @include font-size(10, 12);
  @include letter-spacing(50);

  color: black;
  padding: 15px 0;
  text-align: center;
  text-transform: uppercase;
  background-color: #F2ACC2; 

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.nocibe-dark-button {
  @include font-size(10, 12);
  @include letter-spacing(50);

  color: white;
  padding: 15px 0;
  text-align: center;
  text-transform: uppercase;
  background-color: #382F2D; 

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
}

.nocibe-empty-button {
  font-weight: 600;
  font-size: 14px;
  @include letter-spacing(50);

  align-items: center;
  background-color: transparent;
  border: 1px solid $primary-theme-color;
  color: $primary-theme-color;
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;

  .mobile & {
    padding: 10px;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  .label {
    margin-top: 0.2em;
  }
}

.nocibe-wish-button {
  @include font-size(10, 12);
  @include letter-spacing(50);

  align-items: center;
  background-color: transparent;
  border: 1px solid $primary-theme-color;
  color: $primary-theme-color;
  display: flex;
  justify-content: center;
  padding: 15px 20px;
  text-align: center;
  text-transform: uppercase;

  .mobile & {
    padding: 10px;

    &:before {
      height: 14px;
      width: 16px;
    }
  }

  &.disabled {
    background-color: $shady-lady;
    border: 1px solid $shady-lady;
    opacity: 0.5;
    pointer-events: none;
  }

  &:before {
    content: "";
    background-image: url("../../assets/icons/icon-hearts.svg");
    background-position: center;
    background-position: right;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin-right: 15px;
    width: 20px;
  }

  &.active {
    &:before {
      background-image: url("../../assets/icons/icon-hearts-full.svg");
    }
  }

  .label {
    margin-top: 0.2em;
  }
}
