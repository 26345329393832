$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.store-container {
  margin-bottom: 20px;
  background-color: white;
  text-align: center;
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .mobile &{
    height: auto;
    padding: 20px;
  }

  .store-container__buttons {
    display: flex;
    justify-content: center;
    gap: 30px;
    .mobile & {
      flex-direction: column;
    }
  }

  .store-container__content {
    display: flex;

    .mobile & {
      flex-direction: column;
    }

    .store-container__right {
      width: 136%;
      .mobile & {
        width: 100%;
      }
      .cards-wrapper {
        display: flex;
        overflow: auto;
        height: 100%;

        .mobile & {
          flex-direction: column;
        }
      }
    }

    .store-container__left {
      text-align: left;
      p {
        margin-top: 10px ;
        font-size: 16px;
        font-weight: 400;
        line-height: 18px;
      }

      .text {
        margin: 10px 0;
        font-size: 14px;
        font-weight: 400px;
        .red-text {
          color: #E10054;
        }
      }

    }
  }

  h1 {
    margin: 0;
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    text-align: center;
  }



  .print-message {
    text-align: center;
    max-width: 250px;

    @include font-size(12, 14);

    text-align: center;
    margin-bottom: 10px;
  }

  .store-invitation-button {

    padding: 15px;
    display: flex;
    align-items: center;
    font-weight: 600bold;
    @include font-size(20, 22);
    label {
      margin: 0;
    }
  }
}

.store-invitation-footer {
  color: black;

  @include font-size(10, 12);

  > p {
    margin: 0;
  }
}

.popup-fixed {
  background-image: url("../../../assets/images/bg-compo-3.jpeg") !important;
  background-position: left center !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  padding: 25px !important;
  box-shadow: 1px 1px 3px 0px gray;
  width: 80vw !important;
  max-width: 100vw !important;
  height: 80vh !important;

  .close-button {
    right: 40px !important;
    top: 40px !important;
  }
}

.background {
  // background-image: url("../../../assets/images/bg-compo-6.jpeg") !important;
  // background-position: left center !important;
  // background-repeat: no-repeat !important;
  // background-size: cover !important;
  opacity: 0.9;
}

.overlayed-view-enter, .overlayed-view-enter-active, .overlayed-view-exit, .overlayed-view-exit-active {
  .background {
    opacity: 0.9 !important;
  }
}

.perfume-card .image {
  margin-bottom: 5%;
}