$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
// HAY QUE NESTEAR TODOS LOS ESTILOS!!!!

.perfume-row {
  align-items: center;
  color: $dorado;
  display: flex;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
  text-align: left;
  background-color: white;

  .mobile & {
    display: flex;
    margin: 0;
    padding: 10px 0;
    width: 100%;

  }

  &.selected {
    opacity: 1;
    border-bottom: 1px solid $mercury;

    .main-info-wrapper {
      width: calc(100% - 50px - 100px); // 50px is '.image' flex-basis
   
    }

    .similarity {
      @include font-size(30, 36);
    }
  }

  &.row {
    .actions {
      display: none;
    }
  }

  &.unselected {
    opacity: 0.4;
  }

  &.removed {
    transition: opacity 0.4s ease-in-out;
    opacity: 0;
  }

  .row-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    cursor: pointer;

    .row-top {
      align-items: center;
      display: flex;
      width: 100%;

      .mobile & {
        flex-direction: row;
        flex-wrap: wrap;

        .main-info-wrapper {
          flex: 1 1 100%;
          margin-left: 0;
          margin-right: 10px;

          .mobile & {
            margin-right: 0;
          }
        }

        .rater {
          margin-left: 60px;
          margin-right: 0;
        }

        .remove-btn {
          margin-top: 20px;
          margin-left: auto;
        }
      }

      .alreade-fav {
        @include font-size(12, 14);

        margin-right: 20px;
        opacity: 0.4;
      }

      .check-box {
        background-image: url("../../assets/icons/icon-fake-checkbox-unchecked.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        height: 24px;
        width: 24px;
        flex: 0 0 24px;

        &.checked {
          background-image: url("../../assets/icons/icon-fake-checkbox-checked.svg");
        }
      }
    }

    .row-bottom {
      align-items: center;
      display: flex;
      width: 100%;

      .price-and-buy {
        .button {
          padding: 10px;
        }
      }
    }

    .search-results & {
      .browser & {
        width: calc(100% - 70px); // 70px is 60px for the similarity and 10 for margin
     
      }
    }
  }

  .main-info-wrapper {
    align-items: center;
    display: flex;
    flex: 1 1 0;
    margin-right: 20px;
    overflow: hidden;

    .mobile & {
      margin-left: 20px;
      margin-right: 0;
    }

    .check-box {
      margin-right: 20px;
    }

    .image {
      // background-blend-mode: multiply;
      background-color: #fff;
      background-image: url("../../assets/no-img.png");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      flex: 0 0 50px;
      height: 80px;
      width: 80px;
    }

    .information {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      overflow: hidden;
      color: black;
      width: calc(100% - 50px); // 50px is '.image' flex-basis
      
      .mobile & {
        margin-left: 10px;
        width: calc(100% - 90px); // 50px is '.image' flex-basis + the checbox
      }

      .search-results & {
        .mobile & {
          width: calc(100% - 50px - 50px ); // 50px is '.image' flex-basis and 50px is the similarity + margin
       
        }
      }

      .perfume-name {
        @include font-size(14);

        font-weight: 600;
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      .brand {
        @include ellipsis(100%);
        @include font-size(12);

        font-weight: 400;
      }
    }
  }

  .favorite-icon {
    background-image: url("../../assets/icons/#{$theme}/icon-bookmark.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 20px;
    margin-left: -20px;
    width: 20px;
  }

  .similarity {
    @include font-size(19, 23);

    align-items: baseline;
    display: flex;
    justify-content: flex-end;
    margin-left: auto;
    position: absolute;
    right: 0;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 60px;

    .unit {
      font-size: 0.8em;
    }
  }

  .remove-btn {
    @include font-size(0);

    background-color: transparent;
    background-image: url("../../assets/icons/icon-trash.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    align-self: flex-start;
    cursor: pointer;
    height: 20px;
    width: 20px;
  }

  .price-and-buy {
    align-items: center;
    border-top: 1px solid #B6B4B6;
    display: flex;
    justify-content: space-between;
    padding-top: 10px;
    width: 100%;

    .price {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      justify-content: space-between;
      margin-right: 10px;

      .from {
        @include font-size(10);
        @include letter-spacing(25);
      }

      .price {
        @include font-size(18);
      }
    }
  }
}
