$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.perfume-card {
  background-color: #fff;
  width: 45%;
  display: flex;

  .mobile & {
    flex-direction: column;
    width: 100%;
  }

  .card-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    overflow: hidden;
    padding: 10px 20px 20px;
    position: relative;

    &.is-new-recommendation {}

    .mobile & {
      flex-direction: column;

      .image {
        height: 30vh;
        margin: 0 auto;
      }
    }
  }

  .new-recomendation-box {
    position: relative;

    .marker {
      background-image: url("../../assets/icons/icon-star-full.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
      height: 12px;
      margin-bottom: 5px;
      width: 12px;
    }

    &:hover,
    &.hover {
      .marker {
        + .tooltip {
          opacity: 1;
        }
      }
    }

    .tooltip {
      @include font-size(9, 11);
      @include letter-spacing(25);

      font-weight: 400;
      background-color: #fff;
      border: solid 1px #000;
      color: #000;
      left: -15px;
      opacity: 0;
      padding: 8px;
      pointer-events: none;
      position: absolute;
      text-transform: uppercase;
      top: calc(-100% - 22px);
      transition: opacity .3s;

      &:after {
        background: #fff;
        border-bottom: 1px solid #000;
        border-left: 1px solid #000;
        bottom: -5px;
        content: '';
        display: block;
        height: 8px;
        left: 15px;
        position: absolute;
        transform: rotate(-45deg);
        width: 8px;
      }
    }
  }

  .image {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 1 1 auto;
    width: 75%;
  }

  .similarity-wrapper {
    align-items: center;
    border-radius: 4px;
    display: flex;
    flex-direction: column;

    .circle {
      background-color: #444;
      border-radius: 50%;
      height: 40px;
      position: relative;
      width: 40px;

      .value {
        @include font-size(12, 12);

        color: #fff;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .description {
      @include font-size(6, 10);

      margin-top: 5px;
      text-transform: uppercase;
    }
  }

  .heart-wrapper {
    height: 40px;
    position: relative;
    width: 40px;

    &:hover {
      .tooltip {
        opacity: 1;
      }
    }

    .tooltip {
      position: absolute;
      z-index: 2;
      top: 110%;
      left: 50%;
      white-space: nowrap;

      @include font-size(14, 16);

      transform: translate(-50%, 0);
      pointer-events: none;
      opacity: 0;
      transition: opacity .3s;
      background: $primary-theme-color;
      padding: 10px 15px;
      color: #fff;

      &:after {
        content: '';
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid $primary-theme-color;
        position: absolute;
        top: 1px;
        left: 50%;
        transform: translate(-50%, -100%);
      }
    }

    .heart {
      background-color: #fff;
      background-image: url("../../assets/icons/icon-wish-list.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 60%;
      border-radius: 50%;
      cursor: pointer;
      height: 40px;
      width: 40px;

      &.checked {
        cursor: default;
        background-image: url("../../assets/icons/icon-hearts-full.svg");
      }
    }
  }

  .info-top {
    display: flex;
    left: 0;
    padding: 10px;
    position: absolute;
    top: 0;
    width: 100%;

    .mobile & {
      top: 0;
      position: static;
      padding: 0;
    }

    .info-left {
      flex: 1 1 0;

      // margin-right: 40px;

      .mobile & {
        background-color: rgba(#fff, 0.75);
        border-radius: 4px;
        padding: 10px;
      }

      &:empty {
        display: none;
      }

      .name-and-brand {
        .brand {
          @include font-size(16, 18);
          @include letter-spacing(45);
          @include ellipsis(100%);

          font-weight: 600;
          margin-bottom: 5px;
          text-transform: uppercase;

          .mobile & {
            @include font-size(12, 14);
          }
        }

        .name {
          @include font-size(14, 18);

          font-weight: 400;
          margin-bottom: 10px;

          .mobile & {
            @include font-size(12, 14);

            margin-bottom: 0;
          }
        }
      }

      .new-tag {
        @include font-size(12, 14);

        background-color: rgba(#fff, 0.5);
        border-radius: 4px;
        display: inline-block;
        padding: 5px 5px 3px 5px;
        text-transform: uppercase;
      }
    }

    .info-right {
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-left: auto;

      &:empty {
        display: none;
      }

      > div {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .similarity-wrapper {
        &:not(:only-child) {
          margin-bottom: 10px;
        }
      }
    }
  }

  .info-bottom {
    align-items: stretch;
    display: flex;
    flex-direction: column;
    text-align: left;
    width: 100%;

    // overflow: hidden;

    .name-and-brand {
      overflow: hidden;
      width: 100%;

      .brand {
        @include font-size(14);
        @include letter-spacing(25);
        @include ellipsis(100%);

        font-weight: 600;
        margin-bottom: 5px;
        text-transform: uppercase;
      }

      .name {
        @include font-size(12, 14);
        font-weight: 400;
        height: 1.8rem;
      }
    }
  }

  .stars {
    display: none !important;
    align-items: center;
    direction: rtl;
    display: flex;
    justify-content: flex-end; // because of the rtl direction

    .star {
      background-image: url("../../assets/icons/icon-star.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 15px;
      height: 20px;
      width: 20px;

      &:hover {
        background-image: url("../../assets/icons/icon-star-full.svg");
        cursor: pointer;

        ~ .star {
          background-image: url("../../assets/icons/icon-star-full.svg");
        }
      }
    }
  }

  .price {
    align-items: center;
    border-bottom: 1px solid rgba($shady-lady, 0.1);
    border-top: 1px solid rgba($shady-lady, 0.1);
    display: flex;
    justify-content: space-between;
    padding: 5px 0;
    @media print {
        display: none;
      }


    .from {
      @include font-size(11);
      @include letter-spacing(25);
      @include ellipsis(100%);

      margin-right: 5px;
      font-weight: 400;
    }

    .euros {
      @include font-size(15);

      margin-left: 5px;
      font-weight: 400;
    }
  }

  .actions {
    flex: 1 1 auto;
    margin-top: 10px;

    &:empty {
      display: none;
    }

    .details {
      padding: 0;
      text-align: left;
    }

    // .button {
    //   .mobile & {
    //     @include font-size(8, 10);
    //   }
    // }

    .nocibe-button {
      &:not(:last-child) {
        margin-bottom: 10px;
      }
    }

    .nocibe-wish-button {
      &:after {
        .mobile & {
          margin: 0 0 0 10px;
          height: 15px;
          width: 15px;
        }
      }
    }
  }
}
