$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.search-by {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  overflow: hidden;
  padding: 20px;

  .mobile & {
    padding: 0px 20px 0 20px;
  }

  .list {
    overflow-y: auto;
    height: 100%;
    position: relative;

    &.grouped {
      .element {
        margin-left: 10%;
      }

      .result-group {
        border-bottom: solid 1px $mercury;

        &.expanded {
          .group {
            color: $primary-theme-color;
            font-weight: 400;
            opacity: 1;

            &:hover {
              cursor: default;
            }

            &:after {
              transform: rotate(180deg);
            }
          }
        }

        .group {
          opacity: 0.6;
          text-transform: uppercase;

          &:hover {
            opacity: 1;
          }

          &:after {
            transition: transform 0.2s ease-in-out;
            background-image: url("../../../assets/icons/icon-caret-down.svg");
            background-position: center center;
            background-repeat: no-repeat;
            background-size: contain;
            content: "";
            height: 15px;
            position: absolute;
            right: 20px;
            width: 15px;
          }
        }

        .group-children {
          overflow: hidden;

          // transition: height 2s;
          .result-item {
            &:first-child {
              margin-top: 0px;
            }
          }
        }

        &.collapsed {
          .group-children {
            height: 0px;
          }
        }
      }
    }

    .result-item {
      @include font-size(14, 16);
      @include letter-spacing(20);
      @include first-letter-case;

      font-weight: 400;
      margin: 15px;

      &:hover {
        color: $primary-theme-color;
        cursor: pointer;
      }
    }
  }
}
