$header-height: 50px;
$theme: "nocibe";

:export {
  theme: $theme;
}

$abbey: #49494B;
$alabaster: #F8F7F8;
$americano: #877874;
$cabaret: #d84580;
$razzmatazz: #E10054;
$dorado: #5f504d;
$fountain-blue: #4fbcc2;
$gallery: #eee;
$harvest-gold: #E2A171;
$manatee: #9A9A9C;
$mercury: #eae8e8;
$monza: #cc0044;
$pampas: #FAF9F7;
$shady-lady: #b6b4b6;
$tickle-me-pink: #fc7999;

$primary-theme-color: null;
$secondary-theme-color: null;
$tertiary-theme-color: null;

@if $theme == "nocibe" {
  $primary-theme-color: $razzmatazz;
  $secondary-theme-color: $razzmatazz;
  $tertiary-theme-color: $fountain-blue;
}

:export {
  primaryThemeColor: $primary-theme-color;
  secondaryThemeColor: $secondary-theme-color;
  tertiaryThemeColor: $tertiary-theme-color;
}

$home-color: $harvest-gold;

$base-font-size: 16;
$base-line-height: 1.2;
$base-font-color: $abbey;
$base-font-family: "Avenir", serif;
$base-font-weight: 400;

@mixin ellipsis($value) {
  max-width: $value;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin font-size($size-value: $base-font-size, $line-value: $size-value * $base-line-height) {
  font-size: $size-value * 1px;
  line-height: $line-value * 1px;
  font-size: $size-value / $base-font-size * 1rem;
  line-height: $line-value / $base-font-size * 1rem;
}

@mixin font($font-family, $font-weight: null, $fallback-fonts: "sans-serif") {
  @if $font-family == "Georgia" {
    $fallback-fonts: "serif";
  }

  @if $font-family == "Helvetica-Neue" {
    $fallback-fonts: "sans-serif";
  }

  @if $font-weight == "thin" {
    $font-weight: 100;
  }

  @if $font-weight == "extra-light" or $font-weight == "ultra-light" {
    $font-weight: 100;
  }

  @if $font-weight == "light" {
    $font-weight: 400;
  }

  @if $font-weight == "normal" or $font-weight == "book" or $font-weight == "regular" {
    $font-weight: 400;
  }

  @if $font-weight == "medium" {
    $font-weight: 400;
  }

  @if $font-weight == "semi-bold" or $font-weight == "demi-bold" {
    $font-weight: 600;
  }

  @if $font-weight == "bold" {
    $font-weight: 600;
  }

  @if $font-weight == "extra-bold" {
    $font-weight: 900;
  }

  @if $font-weight == "ultra-bold" or $font-weight == "heavy" or $font-weight == "black" or $font-weight == "ultra" or $font-weight == "ultra-black" or $font-weight == "extra-black" {
    $font-weight: 900;
  }

  font-family: $font-family, unquote($fallback-fonts);
  font-weight: $font-weight;
}

@mixin letter-spacing($value) {
  letter-spacing: $value / 1000 * 1em;
}

@mixin first-letter-case() {
  text-transform: lowercase;
  &:first-letter{
    text-transform: uppercase;
  }
}
.section {
  &.recomendations {
    background-color: rgb(247, 247, 247);

    .print-container {
      @media print {
        height: 297mm !important;
        width: 210mm !important;
        overflow: hidden !important;
        margin: 0 !important;
      }
    }

    .print-header {
      align-items: center;
      background-color: $primary-theme-color;
      color: #fff;
      display: none;
      height: 60px;
      justify-content: center;
      margin-bottom: 10px;

      @media print {
        display: flex;
      }

      h1 {
        font-weight: 400;
        margin: 0;
        text-align: center;
        display: block;
      }
    }

    .recs-header {
      position: sticky;
      top: 0;
      z-index: 1;
      padding: 10px 0;
      text-align: center;
      background-color: rgb(247, 247, 247);

      > .title {
        @include font-size(20, 24);
        @include font-size(34, 34);
        @include letter-spacing(25);

        font-weight: bold;
        
        font-weight: 400;
        margin-bottom: 10px;
        margin-right: 30px;

        .mobile & {}
      }

      > .description {
        @include font-size(16, 16);
        padding-bottom: 10px;
      }

      &.more-recos {
        text-align: left;
        padding-left: 20px;
        background-color: rgb(247, 247, 247);
        > .title {
          font-weight: bold;
          @include font-size(20, 24);
        }

        > .description {
          @include font-size(12, 14);
          font-weight: 400;
          margin-right: 30px;
          text-transform: uppercase;

          .mobile & {
            margin-bottom: 0;
          }
        }
      }

      .filter-bar {
        display: flex;
        align-items: center;
        .mobile & {
          flex-direction: column;
          margin-right: -20px;
          margin-left: -20px;
        }

        .test-button {
          margin: 0;
          .mobile & {
            width: 300px;
          }
        }
      }

      .filters {
        background-color: #F7F7F7;
        position: sticky; // GOOT SHEAT
        top: 0;
        z-index: 2;

        .mobile & {
          flex-direction: column;
          margin: 0 0 10px 0;
          padding: 0 20px;

          &.expanded {
            padding: 10px 20px;
            margin: 10px 0 0 0;
          }

          .react-select:not(:last-child) {
            margin-bottom: 10px;
            margin-right: 0;
          }
        }
      }
    }


    .test-button {
      width: 230px;
      height: 40px;
      margin: 0 auto;
      margin-bottom: 30px;
    }

    .top {
      .mobile & {
        background-color: #fff;

        .card-list {
          margin: 20px;
        }

        .cards-wrapper {
          flex-wrap: wrap;
          margin: 0;
          padding: 0;
          position: relative;
          
          .perfume-card {
            flex: 0 0 100%;
            margin: 0;
          }
        }
      }

      > .top-list {
        flex-wrap: nowrap;

        .mobile & {
          min-height: 0;
          flex: 1 1 0;
        }
      }
    }

    //MMMM nose si va aqui..
    .card-list {
      display: flex;
      flex-wrap: wrap;
      min-height: 50vh;

      &:empty {
        display: none;
      }

      .cards-wrapper {
        display: flex;
        background-color: white;
        margin: 0 5vw;
        width: 100%;

        @media only screen and (min-width: 1441px) {
          margin: 0 10vw;
        }

        // .mobile & {
        //   padding: 0 15px;
        //   width: auto;
        // }
     
      }

      .perfume-card {
        $percentage: percentage(1 / 4);
        $margin: 15px;

        flex: 0 0 calc(#{$percentage} - #{$margin} * 2);
        height: 50vh;
        overflow: hidden;
        margin: $margin;

        // @media print {
        //   height: 34vh;
        // }

        .mobile & {
          flex: 0 0 calc(75vw - #{$margin});
          height: auto;
        }

        &:hover {
          box-shadow: 0 2px 5px 0 rgba(#000, 0.25);
        }

        .card-content {
          cursor: pointer;
        }
      }
    }

    .showing-all {
      align-self: center;
      margin: 20px 0;
    }

    .rest {
      flex: 1 1 auto;

      .card-list {
        margin: 0 5vw;

        @media only screen and (min-width: 1441px) {
          margin: 0 10vw;
        }

        .mobile & {
          margin: 10px 20px 20px 20px;
        }

        .perfume-card {
          .mobile & {
            border-bottom: 1px solid $shady-lady;
            flex: 0 0 100%;
            height: 60vh;
            margin: 15px 0;
            padding-bottom: 15px;
          }
        }
      }

      .separator {
        display: none;
      }

      .discount {
        display: none;
        padding: 10px;

        @media print {
          display: block;
        }

        .flex {
          display: flex;
          justify-content: space-between;

          .description {
            @include font-size(16, 18);

            color: $abbey;
            margin-right: 80px;
            padding: 10px;
          }

          .code {
            .img {
              max-height: 150px;
            }
          }
        }

        .legal {
          color: grey;

          @include font-size(10, 12);
        }
      }
    }

    .after-list {
      align-items: center;
      display: flex;
      flex-direction: column;
      padding: 40px;

      .mobile & {
        display: block;
        padding: 20px;
      }

      .description {
        @include font-size(12);

        margin-bottom: 40px;

        .mobile & {
          margin-bottom: 20px;
        }
      }

      .show-all {
        margin-bottom: 40px;

        .mobile & {
          margin-bottom: 20px;
          margin-right: auto;
        }
      }

      .link-button {
        display: block;
        text-decoration: none;
      }
    }
  }
}
